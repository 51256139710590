import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import Swal from "sweetalert2";

import { isNullOrUndefined } from "../../../../utils/functionsUtils";
import Grid from "../../../Grid";

const WarehouseHierarchy = ({
    show,
    parent,
    handleClose,
    warehouseTypes,
    reduxGetWarehouseTypes,
    reduxFetchWarehouseHierarchy,
    warehouseHierarchy,
    reduxPostWarehouse,
    reduxPatchWarehouse,
    successfulUpdateWarehouseHierarchy,
    errorsUpdateWarehouseHierarchy,
    successfulCreateWarehouseHierarchy,
    errorsCreateWarehouseHierarchy,
    upsertHierarchyResult,
    reduxGetWarehouseById,
    rowEdited,
    reduxResetWarehouseForm,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            type: null,
            name: '',
            warehouseCode: '',
        }
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);

    const [showNewRecord, setShowNewRecord] = useState(false);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    }

    useEffect(() => {
        if (show === false) return;
        initForm();
    }, [show])

    useEffect(() => {
        if (show === false || isNullOrUndefined(parent)) return;

        reduxFetchWarehouseHierarchy({
            idWarehouse: parent.idWarehouse,
            page: currentPage,
            offset: offset,
        })
    }, [parent, currentPage, offset])

    useEffect(() => {
        if (successfulCreateWarehouseHierarchy || successfulUpdateWarehouseHierarchy) {
            Swal.fire(
                "Proceso Exitoso",
                `${upsertHierarchyResult.message}`,
                "success"
            );
            handleRefresh();
        }
        if (errorsCreateWarehouseHierarchy || errorsUpdateWarehouseHierarchy) {
            Swal.fire(
                "Proceso Fallido",
                `${upsertHierarchyResult.message}`,
                "error"
            );
        }
    }, [successfulCreateWarehouseHierarchy, successfulUpdateWarehouseHierarchy, errorsCreateWarehouseHierarchy, errorsUpdateWarehouseHierarchy, upsertHierarchyResult])

    useEffect(() => {
        if (!rowEdited) return;

        setValue('name', rowEdited.name);
        setValue('warehouseCode', rowEdited.warehouseCode);
        setValue('type', rowEdited.type && { value: rowEdited.type.id, label: rowEdited.type.name });
    }, [rowEdited]);

    const initForm = () => {
        setShowNewRecord(false)
        reset();
        reduxGetWarehouseTypes();
    }

    const handleHideForm = () => {
        setShowNewRecord(false);
        reduxResetWarehouseForm();
        reset();
    }

    const handleRefresh = () => {
        setShowNewRecord(false);
        reset();
        reduxResetWarehouseForm();
        reduxFetchWarehouseHierarchy({
            idWarehouse: parent?.idWarehouse,
            page: currentPage,
            offset: offset,
        });
    }

    const handleEditRecord = (data) => {
        reduxGetWarehouseById({
            id: data.idWarehouse
        });
        setShowNewRecord(true);
    }

    const submit = async (data) => {
        if (rowEdited) {
            const warehouseDto = {
                ...data,
                isExternalWarehouse: false,
                idType: data.type.value,
                idParent: parent.idWarehouse
            }
            reduxPatchWarehouse(warehouseDto);
        }
        else {
            const warehouseDto = {
                ...data,
                isExternalWarehouse: false,
                idType: data.type.value,
                idParent: parent.idWarehouse,
            }
            reduxPostWarehouse(warehouseDto);
        }
    }

    const columns = [
        {
            title: "#",
            render: (rowData) => {
                return <span>{rowData.idWarehouse}</span>;
            },
        },
        {
            title: "Nombre",
            render: (rowData) => {
                return <span>{rowData.name}</span>;
            },
        },
        {
            title: "Código",
            render: (rowData) => {
                return <span>{rowData.warehouseCode}</span>;
            },
        },
        {
            title: "Tipo",
            render: (rowData) => {
                return <span>{rowData.type.name}</span>;
            },
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        <button
                            title="Editar"
                            className="btn btn-primary btn-sm btn-circle mr-2"
                            type="button"
                            onClick={e => handleEditRecord(rowData)}
                        >
                            <i className="fas fa-edit"></i>
                        </button>

                    </>
                );
            },
        },
    ];

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <div className="d-flex justify-content-between align-items-center">
                        <Modal.Title>Sub-Bodegas</Modal.Title>
                        <button
                            title='Crear Nuevo Lote'
                            className="btn btn-primary btn-sm btn-circle ml-2"
                            type="button"
                            onClick={() => setShowNewRecord(true)}>
                            <i className='fas fa-plus'></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        showNewRecord ?
                            (
                                <div>
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            {rowEdited ? 'Editar Bodega' : 'Crear nueva Bodega'}
                                        </h6>
                                        <Button variant="none" onClick={() => handleHideForm()}><b>x</b></Button>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit(submit)}>
                                            <div className="row">
                                                <div className="form-group col-4">
                                                    <label className="form-label">
                                                        Tipo
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: 'El tipo de bodega es obligatorio'
                                                        }}
                                                        name="type"
                                                        render={({ field }) => (
                                                            <>
                                                                <Select
                                                                    {...field}
                                                                    isClearable
                                                                    styles={customStyles}
                                                                    options={
                                                                        warehouseTypes && Object.keys(warehouseTypes).length > 0
                                                                            ? warehouseTypes.items
                                                                                .filter((f) => f.isActive)
                                                                                .map((ele, key) => ({
                                                                                    value: ele.id,
                                                                                    label: ele.name,
                                                                                }))
                                                                            : []
                                                                    }
                                                                />
                                                                {
                                                                    errors.type &&
                                                                    <span className="error-message">
                                                                        {errors.type.message}
                                                                    </span>
                                                                }
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group col-5">
                                                    <label className="form-label">
                                                        Nombre
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="name"
                                                        render={({ field }) => (
                                                            <div className="input-group">
                                                                <Form.Control
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control form-control-user ${errors.name && "is-invalid"}`}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="form-group col">
                                                    <label className="form-label">
                                                        Código
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: 'El código de bodega es obligatorio',
                                                            pattern: {
                                                                value: /^[A-Z-0-9]+$/,
                                                                message: 'Por favor ingresa solo letras mayusculas o números',
                                                            }
                                                        }}
                                                        name="warehouseCode"
                                                        render={({ field: { onChange, value } }) => (
                                                            <div className="input-group">
                                                                <Form.Control
                                                                    type="text"
                                                                    className={`form-control form-control-user ${errors.warehouseCode && "is-invalid"}`}
                                                                    disabled={rowEdited ? true : false}
                                                                    onChange={e => onChange(e.target.value.toUpperCase())}
                                                                    value={value}
                                                                />
                                                                {
                                                                    errors.warehouseCode &&
                                                                    <span className="error-message">
                                                                        {errors.warehouseCode.message}
                                                                    </span>
                                                                }
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <button className="btn btn-primary" type="submit">Guardar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                            :
                            <Grid
                                showFilters={false}
                                cols={columns}
                                data={
                                    warehouseHierarchy && Object.keys(warehouseHierarchy).length > 0 ? warehouseHierarchy.items : []
                                }
                                page={
                                    warehouseHierarchy && Object.keys(warehouseHierarchy).length > 0
                                        ? Number(warehouseHierarchy.page)
                                        : currentPage
                                }
                                pages={
                                    warehouseHierarchy && Object.keys(warehouseHierarchy).length > 0
                                        ? Number(warehouseHierarchy.totalPages)
                                        : 0
                                }
                                total={
                                    warehouseHierarchy && warehouseHierarchy.hasOwnProperty("total") ? warehouseHierarchy.total : 0
                                }
                                offset={offset}
                                onChangePage={(page) => setCurrentPage(page)}
                                onChangeRange={(value) => {
                                    setOffset(value);
                                    reduxFetchWarehouseHierarchy({
                                        idWarehouse: parent.idWarehouse,
                                        page: 1,
                                        offset: value,
                                    });
                                }}
                            />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        warehouseTypes: state.warehouseState.warehouseTypes,
        warehouseHierarchy: state.warehouseState.warehouseHierarchy,
        rowEdited: state.warehouseState.rowEdited,
        successfulCreateWarehouseHierarchy: state.warehouseState.successfulCreateWarehouseHierarchy,
        errorsCreateWarehouseHierarchy: state.warehouseState.errorsCreateWarehouseHierarchy,
        successfulUpdateWarehouseHierarchy: state.warehouseState.successfulUpdateWarehouseHierarchy,
        errorsUpdateWarehouseHierarchy: state.warehouseState.errorsUpdateWarehouseHierarchy,
        upsertHierarchyResult: state.warehouseState.upsertHierarchyResult,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWarehouseById: (payload) =>
            dispatch({
                type: "READ_WAREHOUSE_REQUEST",
                value: payload,
            }),
        reduxGetWarehouseTypes: () =>
            dispatch({
                type: 'FETCH_WAREHOUSE_TYPES_REQUEST',
            }),
        reduxFetchWarehouseHierarchy: (payload) =>
            dispatch({
                type: 'FETCH_WAREHOUSE_HIERARCHY_REQUEST',
                value: payload,
            }),
        reduxPostWarehouse: (payload) =>
            dispatch({
                type: 'CREATE_WAREHOUSE_HIERARCHY_REQUEST',
                value: payload,
            }),
        reduxPatchWarehouse: (payload) =>
            dispatch({
                type: 'UPDATE_WAREHOUSE_HIERARCHY_REQUEST',
                value: payload,
            }),
        reduxResetWarehouseForm: () =>
            dispatch({
                type: 'RESET_WAREHOUSE_HIERARCHY_FORM',
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseHierarchy);