import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from '../../components/Grid';
import RadioButton from "../../components/Inputs/RadioButton";

import { Show } from "../../hooks/Show";

import { buttonIcon, buttonTypes } from "../../utils/buttonsUtil";
import { isNullOrUndefined } from '../../utils/functionsUtils';
import Hierarchy from "../../components/Modal/WMS/Warehouse/hierarchy";

const Warehouse = ({
  reduxGetWarehouses,
  warehouses,
  reduxGetWarehouseById,
  rowEdited,
  reduxResetWarehouseForm,
  reduxGetInventoryOperators,
  inventoryOperators,
  reduxPostWarehouse,
  successfulCreateWarehouse,
  errorsCreateWarehouse,
  reduxPatchWarehouse,
  successfulUpdateWarehouse,
  errorsUpdateWarehouse,
  upsertResult,
}) => {
  const {
    handleSubmit: submitModal,
    control: modalControl,
    formState: { errors: modalErrors },
    watch: modalWatch,
    setValue: setModalValue,
    reset: resetModal,
  } = useForm({
    defaultValues: {
      name: '',
      warehouseCode: '',
      isExternalWarehouse: false,
      id_wms_operator: null,
      externalWarehouseCode: null,
    },
    shouldUnregister: true,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const [parentData, setParentData] = useState(null);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);

  const warehouseCodeWatcher = modalWatch("warehouseCode", null);
  const externalWarehouseWatcher = modalWatch("isExternalWarehouse", false);
  const wmsOperatorWatcher = modalWatch("id_wms_operator", null);
  const extWarehouseCodeWatcher = modalWatch("externalWarehouseCode", null);

  const selectStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" }) // Ajusta el valor según tus necesidades
  }

  const [customStyles, setCustomStyles] = useState(selectStyles);

  useEffect(() => {
    reduxGetWarehouses({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }, [currentPage, offset, search, reduxGetWarehouses])

  useEffect(() => {
    if (
      !isNullOrUndefined(warehouseCodeWatcher)
      && (!externalWarehouseWatcher || (externalWarehouseWatcher && !isNullOrUndefined(wmsOperatorWatcher) && !isNullOrUndefined(extWarehouseCodeWatcher)))
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  }, [warehouseCodeWatcher, externalWarehouseWatcher, wmsOperatorWatcher, extWarehouseCodeWatcher]);

  useEffect(() => {
    if (successfulCreateWarehouse || successfulUpdateWarehouse) {
      Swal.fire(
        "Proceso Exitoso",
        `${upsertResult.message}`,
        "success"
      );
      handleClose();
    }
    if (errorsCreateWarehouse || errorsUpdateWarehouse) {
      Swal.fire(
        "Proceso Fallido",
        `${upsertResult.message}`,
        "error"
      );
    }
  }, [successfulCreateWarehouse, successfulUpdateWarehouse, errorsCreateWarehouse, errorsUpdateWarehouse, upsertResult])

  useEffect(() => {
    if (externalWarehouseWatcher) return;

    setModalValue('id_wms_operator', null);
    setModalValue('externalWarehouseCode', null);
  }, [externalWarehouseWatcher, setModalValue])

  useEffect(() => {
    if (!rowEdited) return;
    
    setCustomStyles(selectStyles);

    setModalValue('name', rowEdited.name);
    setModalValue('warehouseCode', rowEdited.warehouseCode);
    setModalValue('isExternalWarehouse', rowEdited.isExternalWarehouse);
    setModalValue('id_wms_operator', rowEdited.wms_operator &&
      { value: rowEdited.wms_operator.idCompany, label: rowEdited.wms_operator.description });
    setModalValue('externalWarehouseCode', rowEdited.externalWarehouseCode);

  }, [rowEdited, setModalValue]);

  const handleOpenWarehouseModal = (data) => {
    reduxGetInventoryOperators();
    
    if (data.idWarehouse) {
      reduxGetWarehouseById({
        id: data.idWarehouse
      })
    }

    setShowModal(true);
  };

  const handleOpenHierarchyModal = (data) => {
    setParentData(data);
    setShowHierarchyModal(true)
  }

  const handleCloseHierarchyModal = () => {
    setParentData(null);
    setShowHierarchyModal(false)
  }

  const handleChangeState = (data) => {
    const warehouseObj = {
      warehouseCode: data.warehouseCode,
      isActive: !data.isActive
    }
    reduxPatchWarehouse(warehouseObj);
  };

  const handleClose = () => {
    reduxResetWarehouseForm();
    resetModal();
    setShowModal(false);
    reduxGetWarehouses({
      page: currentPage,
      offset: offset,
      search: search,
    });
  }

  const onSubmit = (data) => {
    if (rowEdited) {
      const warehouseDto = {
        ...data,
      }
      reduxPatchWarehouse(warehouseDto);
    }
    else {
      const warehouseDto = {
        ...data,
        id_wms_operator: data.id_wms_operator?.value,
      }
      reduxPostWarehouse(warehouseDto);
    }
  }

  const actionButtons = [
    { permission: 'feature:warehouse-create', onClick: handleOpenWarehouseModal, buttonType: buttonTypes.Primary, buttonIcon: buttonIcon.Add }
  ];

  const externalWarehouseOptions = [
    { name: 'No', value: false, disabled: rowEdited },
    { name: 'Si', value: true, disabled: rowEdited },
  ];

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idWarehouse}</span>;
      },
    },
    {
      title: "Nombre",
      render: (rowData) => {
        return <span>{rowData.name}</span>;
      },
      field: "TMW.name",
      searchable: true,
    },
    {
      title: "Código",
      render: (rowData) => {
        return <span>{rowData.warehouseCode}</span>;
      },
      field: "TMW.warehouseCode",
      searchable: true,
    },
    {
      title: "Operador de Inventario",
      render: (rowData) => {
        return <span>{rowData.wms_operator?.description}</span>;
      },
      field: "wms_operator.description",
      searchable: true,
    },
    {
      title: "Acciones",
      render: (rowData) => {
        return (
          <>
            {
              rowData.isActive ?
                (
                  <>
                    <Show when="feature:warehouse-edit">
                      <button
                        title="Jerarquía"
                        className="btn btn-primary btn-sm btn-circle mr-2"
                        type="button"
                        onClick={e => handleOpenHierarchyModal(rowData)}
                      >
                        <i className={buttonIcon.Columns}></i>
                      </button>
                    </Show>
                    <Show when="feature:warehouse-edit">
                      <button
                        title="Editar"
                        className="btn btn-primary btn-sm btn-circle mr-2"
                        type="button"
                        onClick={e => handleOpenWarehouseModal(rowData)}
                      >
                        <i className={buttonIcon.Edit}></i>
                      </button>
                    </Show>
                    <Show when="feature:warehouse-disable">
                      <button
                        title="Desactivar"
                        className="btn btn-danger btn-sm btn-circle"
                        type="button"
                        onClick={e => handleChangeState(rowData)}
                      >
                        <i className="fas fa-times-circle"></i>
                      </button>
                    </Show>
                  </>
                ) : (
                  <Show when="feature:warehouse-enable">
                    <button
                      title="Activar"
                      className="btn btn-primary btn-sm btn-circle mr-2"
                      type="button"
                      onClick={e => handleChangeState(rowData)}
                    >
                      <i className="fas fa-check-circle"></i>
                    </button>
                  </Show>
                )
            }
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <div>
          <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <Header title={"Bodegas"} subtitle={"Módulo para la gestión de Bodegas"} actionButtons={actionButtons} />
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Bodegas
                  </h6>
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      warehouses && Object.keys(warehouses).length > 0 ? warehouses.items : []
                    }
                    page={
                      warehouses && Object.keys(warehouses).length > 0
                        ? Number(warehouses.page)
                        : currentPage
                    }
                    pages={
                      warehouses && Object.keys(warehouses).length > 0
                        ? Number(warehouses.totalPages)
                        : 0
                    }
                    total={
                      warehouses && warehouses.hasOwnProperty("total") ? warehouses.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetWarehouses({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetWarehouses({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <Modal
          show={showModal}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={submitModal(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nueva"} Bodega
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="form-group col-6">
                  <label className="form-label">
                    Nombre de Bodega
                  </label>
                  <Controller
                    control={modalControl}
                    name="name"
                    render={({ field }) => (
                      <div className="input-group">
                        <Form.Control
                          {...field}
                          type="text"
                          className={`form-control form-control-user ${modalErrors.name && "is-invalid"}`}
                        />
                        {
                          modalErrors.name &&
                          <span className="error-message">
                            {modalErrors.name.message}
                          </span>
                        }
                      </div>
                    )}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="form-label">
                    Código de Bodega
                  </label>
                  <Controller
                    control={modalControl}
                    rules={{
                      required: 'El código de bodega es obligatorio',
                      pattern: {
                        value: /^[A-Z-0-9]+$/,
                        message: 'Por favor ingresa solo letras mayusculas o números',
                      }
                    }}
                    name="warehouseCode"
                    render={({ field: { onChange, value } }) => (
                      <div className="input-group">
                        <Form.Control
                          type="text"
                          className={`form-control form-control-user ${modalErrors.warehouseCode && "is-invalid"}`}
                          disabled={rowEdited}
                          onChange={e => onChange(e.target.value.toUpperCase())}
                          value={value}
                        />
                        {
                          modalErrors.warehouseCode &&
                          <span className="error-message">
                            {modalErrors.warehouseCode.message}
                          </span>
                        }
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="form-label">
                    ¿Tiene bodega externa?
                  </label>
                  <Controller
                    control={modalControl}
                    name="isExternalWarehouse"
                    defaultValue={false}
                    render={({ field }) => (
                      <>
                        <RadioButton options={externalWarehouseOptions} fieldController={field} />
                      </>
                    )}
                  />
                </div>
              </div>
              {
                externalWarehouseWatcher &&
                (
                  <>
                    <div className="row">
                      <div className="form-group col">
                        <label className="form-label">
                          Operador de Inventario
                        </label>
                        <Controller
                          control={modalControl}
                          rules={{
                            required: {
                              value: externalWarehouseWatcher,
                              message: 'El Operador de inventario es obligatorio'
                            }
                          }}
                          name="id_wms_operator"
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                isClearable
                                styles={customStyles}
                                isDisabled={rowEdited}
                                options={
                                  inventoryOperators && Object.keys(inventoryOperators).length > 0
                                    ? inventoryOperators.items
                                      .filter((f) => f.isActive)
                                      .map((ele, key) => ({
                                        value: ele.idCompany,
                                        label: ele.description,
                                      }))
                                    : []
                                }
                              />
                              {
                                modalErrors.id_wms_operator &&
                                <span className="error-message">
                                  {modalErrors.id_wms_operator.message}
                                </span>
                              }
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                        <div className="form-group">
                          <label className="form-label">
                            Código de Bodega Externa
                          </label>
                          <Controller
                            control={modalControl}
                            rules={{
                              required: {
                                value: externalWarehouseWatcher,
                                message: 'El código de bodega externo es obligatorio'
                              },
                              maxLength: {
                                value: 12,
                                message: 'Longitud máxima de 12 caracteres.'
                              },
                              pattern: {
                                value: /^[A-Za-z0-9]+$/,
                                message: 'Por favor ingresa solo letras o números',
                              }
                            }}
                            name="externalWarehouseCode"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Form.Control
                                  type="text"
                                  maxLength={12}
                                  onChange={e => onChange(e.target.value.toUpperCase())}
                                  value={value}
                                  className={`form-control ${modalErrors.externalWarehouseCode && "is-invalid"}`}
                                  disabled={rowEdited}
                                />
                                {
                                  modalErrors.externalWarehouseCode &&
                                  <span className="error-message">
                                    {modalErrors.externalWarehouseCode.message}
                                  </span>
                                }
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit" disabled={allFieldsFilled === false}>
                Guardar
              </Button>
            </Modal.Footer>
          </form>
          </Modal>
        </div>
        <Hierarchy show={showHierarchyModal} parent={parentData} handleClose={handleCloseHierarchyModal} />
    </div>
  );

};

const mapStateToProps = (state) => {
  return {
    warehouses: state.warehouseState.warehouses,
    successfulCreateWarehouse: state.warehouseState.successfulCreateWarehouse,
    errorsCreateWarehouse: state.warehouseState.errorsCreateWarehouse,
    successfulUpdateWarehouse: state.warehouseState.successfulUpdateWarehouse,
    errorsUpdateWarehouse: state.warehouseState.errorsUpdateWarehouse,
    upsertResult: state.warehouseState.upsertResult,
    rowEdited: state.warehouseState.rowEdited,
    branchesWithoutWarehouse: state.branchState.branchesWithoutWarehouse,
    inventoryOperators: state.companyState.inventoryOperators,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetWarehouses: (payload) =>
      dispatch({
        type: "FETCH_WAREHOUSES_REQUEST",
        value: payload,
      }),
    reduxGetWarehouseById: (payload) =>
      dispatch({
        type: "READ_WAREHOUSE_REQUEST",
        value: payload,
      }),
    reduxGetBranchesWithoutWarehouse: (payload) =>
      dispatch({
        type: "FETCH_BRANCHES_WITHOUTWAREHOUSE_REQUEST",
        value: payload,
      }),
    reduxGetInventoryOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYINVENTORYOPERATOR_REQUEST',
        value: payload,
      }),
    reduxPostWarehouse: (payload) =>
      dispatch({
        type: 'CREATE_WAREHOUSE_REQUEST',
        value: payload,
      }),
    reduxPatchWarehouse: (payload) =>
      dispatch({
        type: 'UPDATE_WAREHOUSE_REQUEST',
        value: payload,
      }),
    reduxResetWarehouseForm: () =>
      dispatch({
        type: 'RESET_WAREHOUSE_FORM',
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);